
import { defineComponent } from "vue";

export default defineComponent({
    setup() {
        const navigateToLogin = () => {
            location.assign("/login");
        };

        return { navigateToLogin };
    },
});
